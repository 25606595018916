import { Modal, ModalHeader, ModalBody, FormGroup, Label, Button } from 'reactstrap';



const UserProfileModal = ({ isOpen, toggle, profileIcon}) => {
    

    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            id='profileModal'>
            <ModalHeader toggle={toggle}>
                User Profile Details
            </ModalHeader>
            <ModalBody>
                <img src={profileIcon} alt='profile pic' style={{ border: '1px solid #000000', width:'30%', display: 'flex' }}/>
                
            </ModalBody>
        </Modal>
    );
};

export default UserProfileModal;