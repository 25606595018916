import { validateCampRegistrationForm }  from '../utils/validateCampRegistrationForm';
import { postCampRegisterUser } from '../features/camp/campSlice';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const CampRegistrationForm = () => {
    const [deposit, setDeposit] = useState('');
    const campPrice = 235;
    const dispatch = useDispatch();

    const initialFormValues = {
        condado:'',
        firstname: '',
        lastname: '',
        age: '',
        maritalStatus: '',
        phone: '',
        address: '',
        city:'',
        state:'',
        zipcode:'',
        country:'',
        email: '',
        church: '',
        handicapped: '',
        physicalCondition: '',
        pastorSignature: '',
        damaSignature: '',
        balance: '',
        deposit: '',
        paidComplete: 'no',
        fecha: ''
    };

    const handleSubmit = (values, {resetForm}) => {
        const registerUser = {
            condado: values.condado,
            firstname: values.firstname,
            lastname: values.lastname,
            age: values.age,
            maritalStatus: values.maritalStatus,
            phone: values.phone,
            address: values.address,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country: values.country,
            email: values.email,
            church: values.church,
            handicapped: values.handicapped,
            physicalCondition: values.physicalCondition,
            pastorSignature: values.pastorSignature,
            damaSignature: values.damaSignature,
            balance: values.balance,
            deposit: values.deposit,
            paidComplete: 'no',
            fecha: values.fecha,
            date: new Date(Date.now()).toISOString()
        };
        dispatch(postCampRegisterUser(registerUser));
        resetForm();
    };

    return(
        <>
            <h1 className='centerFormHeading'>Asociacíon de Damas</h1>
            <h2 className='centerFormHeading'>Mensajeras del Señor</h2>
            <h5 className='centerFormHeading'>Del Concilio Latino Americano</h5>
            <h2 className='centerFormHeading'>Campamento General</h2>
            <hr style={{ width: '50%', margin: '1rem auto'}}/>
            <Formik
                initialValues={initialFormValues}
                validate={validateCampRegistrationForm}
                onSubmit={handleSubmit}
            >
            {({ setFieldValue }) => (
                <Form>
                    <FormGroup>
                        <Row style={{display:'flex', justifyContent:'center', margin: '3rem 0rem'}}>
                            <Col md='2'>
                                <Label htmlFor='condado'>
                                    Condado :
                                </Label>
                            </Col>
                            <Col md='3'>
                                <Field
                                    id='condado'
                                    placeholder='Condado' 
                                    name='condado' 
                                    className='form-control'
                                    as='select'>
                                    <option value=''>Click to Select</option>
                                    <option value='Alabama'>Alabama</option>
                                    <option value='Alaska'>Alaska</option>
                                    <option value='Arizona'>Arizona</option>
                                    <option value='Arkansas'>Arkansas</option>
                                    <option value='California'>California</option>
                                    <option value='Colorado'>Colorado</option>
                                    <option value='Connecticut'>Connecticut</option>
                                    <option value='Delaware'>Delaware</option>
                                    <option value='Florida'>Florida</option>
                                    <option value='Georgia'>Georgia</option>
                                    <option value='Hawaii'>Hawaii</option>
                                    <option value='Idaho'>Idaho</option>
                                    <option value='Illinois'>Illinois</option>
                                    <option value='Indiana'>Indiana</option>
                                    <option value='Iowa'>Iowa</option>
                                    <option value='Kansas'>Kansas</option>
                                    <option value='Kentucky'>Kentucky</option>
                                    <option value='Lousiana'>Lousiana</option>
                                    <option value='Maine'>Maine</option>
                                    <option value='Maryland'>Maryland</option>
                                    <option value='Massachusetts'>Massachusetts</option>
                                    <option value='Michigan'>Michigan</option>
                                    <option value='Minnesota'>Minnesota</option>
                                    <option value='Mississippi'>Mississippi</option>
                                    <option value='Missouri'>Missouri</option>
                                    <option value='Montana'>Montana</option>
                                    <option value='Nebraska'>Nebraska</option>
                                    <option value='Nevada'>Nevada</option>
                                    <option value='New Hamphire'>New Hamphire</option>
                                    <option value='New Jersey'>New Jersey</option>
                                    <option value='New Mexico'>New Mexico</option>
                                    <option value='New York'>New York</option>
                                    <option value='North Carolina'>North Carolina</option>
                                    <option value='North Dakota'>North Dakota</option>
                                    <option value='Ohio'>Ohio</option>
                                    <option value='Oklahoma'>Oklahoma</option>
                                    <option value='Oregon'>Oregon</option>
                                    <option value='Pennsylvania'>Pennsylvania</option>
                                    <option value='Puerto Rico'>Puerto Rico</option>
                                    <option value='Rhode Island'>Rhode Island</option>
                                    <option value='South Carolina'>South Carolina</option>
                                    <option value='South Dakota'>South Dakota</option>
                                    <option value='Tennessee'>Tennessee</option>
                                    <option value='Texas'>Texas</option>
                                    <option value='Utah'>Utah</option>
                                    <option value='Vermont'>Vermont</option>
                                    <option value='Virginia'>Virginia</option>
                                    <option value='Washington'>Washington</option>
                                    <option value='West Virginia'>West Virginia</option>
                                    <option value='Wisconsin'>Wisconsin</option>
                                    <option value='Wyoming'>Wyoming</option>
                                </Field>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='firstname'>
                                    First Name :
                                </Label>
                                <Field 
                                    id='firstname'
                                    name='firstname'
                                    placeholder='Nombre'
                                    className='form-control'
                                />
                                <ErrorMessage name='firstname'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='lastname'>
                                    Last Name :
                                </Label>
                                <Field 
                                    id='lastname'
                                    name='lastname'
                                    placeholder='Apellido'
                                    className='form-control'
                                />
                                <ErrorMessage name='lastname'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col md='2' className='campFormSpacing'>
                                <Label htmlFor='age'>
                                    Age :
                                </Label>
                                <Field 
                                    id='age'
                                    name='age'
                                    placeholder='Edad'
                                    className='form-control'
                                />
                                <ErrorMessage name='age'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='maritalStatus'>
                                    Marital Status :
                                </Label>
                                <Field 
                                    id='maritalStatus'
                                    name='maritalStatus' 
                                    className='form-control'                                  
                                    as='select'>
                                        <option value=''>Click to Select</option>
                                        <option value='Married'>Married</option>
                                        <option value='Divorced'>Divorced</option>
                                        <option value='Single'>Single</option>
                                        <option value='Widowed'>Widowed</option>
                                </Field>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='address'>
                                    Address :
                                </Label>
                                <Field 
                                    id='address'
                                    name='address'
                                    placeholder='Dirección'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='city'>
                                    City :    
                                </Label>
                                <Field 
                                    id='city'
                                    name='city'
                                    placeholder='Cuidad'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='2' className='campFormSpacing'>
                                <Label htmlFor='state'>
                                    State :
                                </Label>
                                <Field
                                    id='state' 
                                    name='state' 
                                    className='form-control'
                                    as='select'>
                                    <option value=''>Click to Select</option>
                                    <option value='AL'>AL</option>
                                    <option value='AK'>AK</option>
                                    <option value='AZ'>AZ</option>
                                    <option value='AR'>AR</option>
                                    <option value='CA'>CA</option>
                                    <option value='CO'>CO</option>
                                    <option value='CT'>CT</option>
                                    <option value='DE'>DE</option>
                                    <option value='FL'>FL</option>
                                    <option value='GA'>GA</option>
                                    <option value='HI'>HI</option>
                                    <option value='ID'>ID</option>
                                    <option value='IL'>IL</option>
                                    <option value='IN'>IN</option>
                                    <option value='IA'>IA</option>
                                    <option value='KS'>KS</option>
                                    <option value='KY'>KY</option>
                                    <option value='LA'>LA</option>
                                    <option value='ME'>ME</option>
                                    <option value='MD'>MD</option>
                                    <option value='MA'>MA</option>
                                    <option value='MI'>MI</option>
                                    <option value='MN'>MN</option>
                                    <option value='MS'>MS</option>
                                    <option value='MO'>MO</option>
                                    <option value='MT'>MT</option>
                                    <option value='NE'>NE</option>
                                    <option value='NV'>NV</option>
                                    <option value='NH'>NH</option>
                                    <option value='NJ'>NJ</option>
                                    <option value='NM'>NM</option>
                                    <option value='NY'>NY</option>
                                    <option value='NC'>NC</option>
                                    <option value='ND'>ND</option>
                                    <option value='OH'>OH</option>
                                    <option value='OK'>OK</option>
                                    <option value='OR'>OR</option>
                                    <option value='PA'>PA</option>
                                    <option value='PR'>PR</option>
                                    <option value='RI'>RI</option>
                                    <option value='SC'>SC</option>
                                    <option value='SD'>SD</option>
                                    <option value='TN'>TN</option>
                                    <option value='TX'>TX</option>
                                    <option value='UT'>UT</option>
                                    <option value='VT'>VT</option>
                                    <option value='VA'>VA</option>
                                    <option value='WA'>WA</option>
                                    <option value='WV'>WV</option>
                                    <option value='WI'>WI</option>
                                    <option value='WY'>WY</option>
                                </Field>
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='zipcode'>
                                    Zip Code :
                                </Label>
                                <Field 
                                    id='zipcode'
                                    name='zipcode'
                                    placeholder='Zip Code'
                                    className='form-control'
                                />
                                 <ErrorMessage name='zipcode'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='country'>
                                    Country : 
                                </Label>
                                <Field 
                                    id='country'
                                    name='country'
                                    placeholder='Pais'
                                    className='form-control'
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='email'>
                                    E-Mail :
                                </Label>
                                <Field 
                                    id='email'
                                    name='email'
                                    placeholder='Correo Electronico'
                                    className='form-control'
                                />
                                <ErrorMessage name='email'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='phone'>
                                    Phone Number :
                                </Label>
                                <Field 
                                    id='phone'
                                    name='phone'
                                    placeholder='Telefono'
                                    className='form-control'
                                />
                                <ErrorMessage name='phone'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='physicalCondition'>
                                    Physical Condition :
                                </Label>
                                <Field 
                                    id='physicalCondition'
                                    name='physicalCondition'
                                    placeholder=' Alguna Condicion Física'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='handicapped'>
                                    Handicapped (Incapacitado):
                                </Label>
                                <div style={{
                                            display:'flex', 
                                            justifyContent:'center', 
                                            alignSelf:'center',
                                            padding: '5px', 
                                            border: '1px #FFFFFF solid',
                                            borderRadius: '15px'}}>
                                    <Label style={{ marginBottom: '0px'}}>
                                        <Field 
                                        id='handicapped'
                                        name='handicapped'
                                        type='radio'
                                        value='Yes'
                                        />{' '}
                                            Yes
                                    </Label>
                                    <Label style={{ marginLeft: '3rem', marginBottom: '0px' }}>
                                        <Field 
                                        id='handicapped'
                                        name='handicapped'
                                        type='radio'
                                        value='No'
                                        />{' '}
                                            No
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='church'>
                                    Church :
                                </Label>
                                <Field 
                                    id='church'
                                    name='church'
                                    placeholder='Iglesia'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='pastorSignature'>
                                    Pastor's Signature :
                                </Label>
                                <Field 
                                    id='pastorSignature'
                                    name='pastorSignature'
                                    placeholder='Firma del Pastor'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='damaSignature'>
                                    Signature :
                                </Label>
                                <Field 
                                    id='damaSignature'
                                    name='damaSignature'
                                    placeholder='Su Firma'
                                    className='form-control'
                                />
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='fecha'>
                                    Date :
                                </Label>
                                <Field 
                                    id='fecha'
                                    name='fecha'
                                    type='date'
                                    className='form-control'
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing centeredLabel'>
                                <Label>
                                    Precio de Campamento : ${campPrice}.00
                                </Label>
                            </Col>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='deposit'>
                                    Deposit : $
                                </Label>
                                <Field 
                                    id='deposit'
                                    name='deposit'
                                    placeholder='Deposito $'
                                    className='form-control'
                                    onChange={e => {
                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                        setDeposit(value);
                                        setFieldValue('deposit', value);

                                        //Update balance after deposit changes
                                        const updatedBalance = campPrice - value;
                                        setFieldValue('balance', updatedBalance);
                                    }}
                                />
                                <ErrorMessage name='deposit'>
                                    {msg => <span className='text-warning'>{msg}</span>}
                                </ErrorMessage>
                            </Col>
                            <Col  className='campFormSpacing'>
                                <Label>
                                    Balance : ${campPrice - deposit}.00
                                </Label>
                            </Col>
                        </Row>
                    </FormGroup>
                    <Button type='submit' color='primary' 
                            style={{ 
                                    display: 'block', 
                                    marginLeft: 'auto', 
                                    marginRight: 'auto' }}>
                        Register & Proceed to Payment
                    </Button>
                </Form>
            )}
            </Formik>
        </>
    )
};

export default CampRegistrationForm;