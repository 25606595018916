import { validateContactForm } from '../utils/validateContactForm';
import { postVisitor } from '../features/visitors/visitorSlice';
import { Col, Button, Label, FormGroup } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';

const ContactForm = () => {

    const dispatch = useDispatch();

    const initialFormValues = {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        feedback: ''
    };

    const handleSubmit = (values, {resetForm}) => {
        const petition = {
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            email: values.email,
            feedback: values.feedback,
            date: new Date(Date.now()).toISOString(),
        };
        dispatch(postVisitor(petition));
        resetForm();
    }

    return(
        <Formik 
            initialValues={initialFormValues}
            validate={validateContactForm}
            onSubmit={handleSubmit}    
        >
            <Form>
                <FormGroup row className='form-center'>
                    <Label htmlfor='firstname' md='2'>
                        Nombre
                    </Label>
                    <Col md='5'>
                        <Field
                            name='firstname'
                            placeholder='Nombre'
                            className='form-control' />
                        <ErrorMessage name='firstname'>
                            {(msg) => <span className='text-danger'>{msg}</span>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row className='form-center'>
                    <Label htmlfor='lastname' md='2'>
                        Apellido
                    </Label>
                    <Col md='5'>
                        <Field
                            name='lastname'
                            placeholder='Apellido'
                            className='form-control' />
                        <ErrorMessage name='lastname'>
                            {(msg) => <span className='text-danger'>{msg}</span>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row className='form-center'>
                    <Label htmlfor='phone' md='2'>
                        Telefono
                    </Label>
                    <Col md='5'>
                        <Field
                            name='phone'
                            placeholder='Telefono'
                            className='form-control' />
                        <ErrorMessage name='phone'>
                            {(msg) => <span className='text-danger'>{msg}</span>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row className='form-center'>
                    <Label htmlfor='email' md='2'>
                        E-Mail
                    </Label>
                    <Col md='5'>
                        <Field
                            name='email'
                            placeholder='E-Mail'
                            className='form-control' />
                        <ErrorMessage name='email'>
                            {(msg) => <span className='text-danger'>{msg}</span>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row className='form-center'>
                    <Label htmlfor='feedback' md='2'>
                        Peticion
                    </Label>
                    <Col md='5'>
                        <Field
                            name='feedback'
                            placeholder='Su Mensaje'
                            as='textarea'
                            rows='10'
                            className='form-control' />
                    </Col>
                </FormGroup>
                <FormGroup row className='form-center'>
                    <Col md={{ size: 10, offset: 2 }}>
                        <Button 
                                color='primary' 
                                className='btn btn-primary btn-lg' 
                                type='submit'
                                style={{ margin: '1rem' }}>
                            Submit
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    );
}

export default ContactForm;