import userIcon from '../../app/assets/img/userIcon.jpg';
import { NavLink } from 'reactstrap';
import { useState } from 'react';
import UserProfileModal from './UserProfileModal';

const UserAvatar = () => {

    const [profileOpen, setProfileOpen] = useState(false);

    const toggleProfileModal = () => {
        setProfileOpen(!profileOpen);
    };

    return(
        <>
            <div style={{ width: '3rem', height: '3rem' }}>
                <NavLink onClick={toggleProfileModal}>
                    <img
                        src={userIcon}
                        alt={'user icon'}
                        style={{ width: '100%', height: '100%' }}
                    />
                </NavLink>
            </div>
            <UserProfileModal isOpen={profileOpen} toggle={toggleProfileModal} profileIcon={userIcon}/>
        </>
        
    );
};

export default UserAvatar;