export const validateCampRegistrationForm = (values) => {
    const errors = {};

    if(!values.firstname){
        errors.firstname = 'Requerido';
    } else if (values.firstname.length <= 2){
        errors.firstname = 'Tiene que ser mayor de 2 caracteres.';
    } else if (values.firstname.length > 15){
        errors.firstName = 'Tiene que ser menos de 15 caracteres.';
    }

    if(!values.lastname){
        errors.lastname = 'Requerido';
    } else if (values.lastname.length <= 2){
        errors.lastname = 'Tiene que ser mayor de 2 caracteres.';
    } else if (values.lastname.length > 15){
        errors.lastname = 'Tiene que ser menos de 15 caracteres.';
    }

    const reg = /^\d+$/;
    //const reg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/; //<----- This regular expression receives multiple phone number formats.
   
    if(!reg.test(values.phone)){
        errors.phone = 'El numero de telefono debe de contener solo numeros.';       
    } else if(values.phone.length !== 10){
        errors.phone = 'El numero de telefono debe de ser de 10 digitos';
    }

    if(!values.email.includes('@')){
        errors.email = 'La dirreción de correro electornico debe contener un careacter de: @';
    }

    if(values.zipcode.length !== 5){
        errors.zipcode = 'Su zip code debe contener 5 numeros';
    }

    if(!reg.test(values.age)){
        errors.age = 'Su edad solo debe contener numeros';
    } else if (values.age.length > 3){
        errors.age = 'Su edad solo puede contener 3 digitos al maximo'
    }

    if(!reg.test(values.deposit)){
        errors.deposit = 'Su deposito debe contener solo numeros'
    }
    
    return errors;
};
