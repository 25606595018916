import { Nav, NavItem, Navbar, NavbarBrand, Collapse, NavbarToggler, Button } from 'reactstrap';
import { isAuthenticated, userLogout, validateLogin } from '../features/users/userSlice';
import UserLoginForm from '../features/users/UserLoginForm';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../app/assets/logo/whiteEmblem.svg';
import UserAvatar from '../features/users/UserAvatar';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';    

const Navigator = () => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const auth = useSelector(isAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(validateLogin());
    }, [dispatch]);

    const toggleMenu = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };  

    const toggleLoginModal = () => {
        setLoginModalOpen(!loginModalOpen);
    };
    console.log('auth before userOptions: ', auth);
    const userOptions = auth ? (
        <>
            <UserAvatar />
            <Button 
                outline
                onClick={() => dispatch(userLogout())}
                style={{
                    color: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    marginLeft: '10px'
                }}    
            >
                <i className='fa fa-sign-out fa-lg' />Logout
            </Button>
        </>
    ) : (
        
        <>
           {/* Here I can display a login button, instead of using the church logo as a secret login.  */}
           <NavItem>
                <Button onClick={toggleLoginModal}>
                    <i className="fa fa-sign-in fa-lg" /> Login
                </Button>
            </NavItem>
        </>
    );

    return(
        <>
            <Navbar dark sticky='top' expand='md'>
                <NavLink className='nav-link' to='/'>
                    <NavbarBrand className='ms-5'>
                        <img src={Logo} alt='Damas Logo' className='float-start' style={{ color: '#FFFFFF'}}/>
                        <h1>ADMS</h1>
                    </NavbarBrand>
                </NavLink>
                <NavbarToggler onClick={() => setMenuOpen(!menuOpen)} />
                <Collapse isOpen={menuOpen} navbar style={{ textAlign: 'right'}}>
                    <Nav className='ms-auto' navbar>
                        <NavItem>
                            <NavLink className='nav-link' to='/'>
                                <i className='fa fa-home fa-lg' /> Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink 
                                className='nav-link menu-item'
                                to='/directory'
                                onMouseEnter={() => toggleMenu(0)}
                                onMouseLeave={() => toggleMenu(null)}>
                                    <i className='fa fa-list fa-lg' /> Directory
                                {
                                    activeMenu === 0 && (
                                        <div className='dropdown-content'>
                                            <NavItem>
                                                <NavLink 
                                                        className='nav-link'
                                                        to='/directory/convention'>
                                                            Convención
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink 
                                                        className='nav-link'
                                                        to='/directory/camp'>
                                                            Campamento
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink 
                                                        className='nav-link'
                                                        to='/directory/regions'>
                                                            Regiones
                                                </NavLink>
                                            </NavItem>
                                            <hr />
                                            <NavItem>
                                                <NavLink 
                                                        className='nav-link'
                                                        to='/directory/market'>
                                                            Mercadería
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    )
                                }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/aboutus'>
                                <i className='fa fa-info fa-lg' /> About Us
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/contactus'>
                                <i className='fa fa-address-card fa-lg' /> Contact Us
                            </NavLink>
                        </NavItem>
                        {userOptions}
                    </Nav>
                </Collapse>
            </Navbar>
            <UserLoginForm isOpen={loginModalOpen} toggle={toggleLoginModal} />
        </>
    )
};

export default Navigator;